import noUiSlider from 'nouislider/dist/nouislider.min.js';
import noUiSliderStyles from 'nouislider/dist/nouislider.min.css';
import priceRangeStyles from '../../sass/includes/_price_sldier.scss';

function initPriceSlider(filterPriceNode) {
    const maxPriceInput = filterPriceNode.getElementsByClassName("price-max-input")[0];
    const minPriceInput = filterPriceNode.getElementsByClassName("price-min-input")[0];
    if (maxPriceInput === undefined || !maxPriceInput) {
        console.error('initPriceSlider: maxPriceInput input not found by class "price-max-input"');
        return false;
    }
    if (minPriceInput === undefined || !minPriceInput) {
        console.error('initPriceSlider: minPriceInput input not found by class "price-min-input"');
        return false;
    }
    const inputNodes = [
        minPriceInput,
        maxPriceInput
    ];
    const sliderNode = filterPriceNode.getElementsByClassName('slider')[0];
    if (sliderNode === undefined || !sliderNode) {
        console.error('initPriceSlider: sliderNode not found by class "slider"');
        return false;
    }
    let minPriceValue =  parseInt(sliderNode.dataset.min, 10);
    let maxPriceValue =  parseInt(sliderNode.dataset.max, 10);
    let currentMinPriceValue =  parseInt(sliderNode.dataset.currentmin, 10);
    let currentMaxPriceValue =  parseInt(sliderNode.dataset.currentmax, 10);
    if (isNaN(minPriceValue)) {
        console.error('initPriceSlider: minPriceValue convereted badly from data attribut of: ', sliderNode);
        console.warn('initPriceSlider: minPriceValue set to 0');
        minPriceValue = 0;
    }
    if (isNaN(maxPriceValue)) {
        console.error('initPriceSlider: maxPriceValue convereted badly from data attribut of: ', sliderNode);
        console.warn('initPriceSlider: maxPriceValue set to 100000');
        maxPriceValue = 100000;
    }
    if (isNaN(currentMinPriceValue)) {
        console.error('initPriceSlider: currentMinPriceValue convereted badly from data attribut of: ', sliderNode);
        console.warn('initPriceSlider: currentMinPriceValue set to minPriceValue');
        currentMinPriceValue = minPriceValue;
    }
    if (isNaN(currentMaxPriceValue)) {
        console.error('initPriceSlider: currentMaxPriceValue convereted badly from data attribut of: ', sliderNode);
        console.warn('initPriceSlider: currentMaxPriceValue set to minPriceValue');
        currentMaxPriceValue = maxPriceValue;
    }
    let representFromNode = filterPriceNode.getElementsByClassName('represent-from-value')[0];
    let representToNode = filterPriceNode.getElementsByClassName('represent-to-value')[0];
    if (representFromNode === undefined || !representFromNode) {
        console.error('initPriceSlider: representFromNode not found by class "represent-from-value"');
        return false;
    }
    if (representToNode === undefined || !representToNode) {
        console.error('initPriceSlider: representToNode not found by class "represent-to-value"');
        return false;
    }
    const representNodes = [
        representFromNode,
        representToNode
    ];
    noUiSlider.create(sliderNode, {
        start: [currentMinPriceValue, currentMaxPriceValue],
        connect: true,
        range: {
            'min': minPriceValue,
            'max': maxPriceValue
        }
    });
    sliderNode.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
        inputNodes[handle].value = Math.floor(values[handle]);
        representNodes[handle].innerHTML = Math.floor(values[handle]);
    });
    const updateSliderStart = function(min, max) {
        sliderNode.noUiSlider.updateOptions({
            start: [min, max]
        });
    }
    const getInputValues = function() {
        let minInputValue = parseInt(minPriceInput.value, 10);
        let maxInputValue = parseInt(maxPriceInput.value, 10);
        if (isNaN(minInputValue)) {
            minInputValue = minPriceValue;
        }
        if (isNaN(maxPriceValue)) {
            maxInputValue = maxPriceValue;
        }
        return [minInputValue, maxInputValue];
    }
    minPriceInput.addEventListener('change', function() {
        sliderNode.noUiSlider.set([getInputValues()[0], null]);
    });
    maxPriceInput.addEventListener('change', function() {
        sliderNode.noUiSlider.set([null, getInputValues()[1]]);
    });
}

document.addEventListener('DOMContentLoaded', function(){
    const priceSliderContainers = document.getElementsByClassName('custom-price-slider');
    for(let i = 0; i < priceSliderContainers.length; i++) {
        let priceSliderContainer = priceSliderContainers.item(i);
        initPriceSlider(priceSliderContainer);
    }
});
